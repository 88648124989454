import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { globalSettingsSystemRolesActorsGroupsRoute } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-actors-groups-routes';

export const globalSettingsSystemRolesActorsGroupsRouteEntry = createEntry(
	globalSettingsSystemRolesActorsGroupsRoute,
	{
		layout: globalSettingsLayout,
		resources: [navigationSidebarGlobalResource],

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
		},
	},
);
