import React, { type ComponentType } from 'react';
import { lazy } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';
import type { LegacyTeamProfileCardBaseProps } from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LegacyTeamProfileCard = lazy(
	() => import(/* webpackChunkName: "async-legacy-team-profile-card" */ './index'),
);

export const AsyncLegacyTeamProfileCard: ComponentType<LegacyTeamProfileCardBaseProps> = (
	props: LegacyTeamProfileCardBaseProps,
) => (
	<JSErrorBoundary id="legacy-team-profile-card" packageName="profilecard-next">
		<Placeholder name="async-legacy-team-profile-card" fallback={null}>
			<LegacyTeamProfileCard {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default AsyncLegacyTeamProfileCard;
