import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { WorkflowPublicationStatus, LEGACY_EDITOR, NEW_EDITOR } from '../../common/types';

const SET_GLOBAL_SCOPE_USER_PREFERENCE_MUTATION = `
    mutation SetGlobalScopeUserPreferenceMutation($input: SetMyPreferenceInput!) {
        setMyPreference(input: $input) {
            success
        }
    }
`;

const setGlobalScopeUserPreference = async (input: { key: string; value: string }) => {
	const result = await performPostRequest('/rest/gira/1/', {
		body: JSON.stringify({
			query: SET_GLOBAL_SCOPE_USER_PREFERENCE_MUTATION,
			variables: {
				input,
			},
		}),
	});

	if (result.data.setMyPreference.success !== true) {
		throw new Error(`Error setting user preference: ${input.key}`, {
			cause: result.errors?.[0] ?? 'unknown',
		});
	}
};

export const changeDefaultWorkflowEditorToLegacy = async (
	analyticsEvent: UIAnalyticsEvent,
	workflowPublicationStatus: WorkflowPublicationStatus,
) => {
	try {
		await setGlobalScopeUserPreference({
			key: 'jira.user.workflow.editor.global.scope.enrolment',
			value: LEGACY_EDITOR,
		});

		fireTrackAnalytics(analyticsEvent, 'legacyWorkflowEditorUserPreference set', {
			workflowPublicationStatus:
				workflowPublicationStatus === WorkflowPublicationStatus.Dirty
					? 'Unsaved' // user-friendly term to use instead of Dirty
					: WorkflowPublicationStatus[workflowPublicationStatus],
		});
	} catch (error) {
		fireErrorAnalytics({
			meta: {
				id: 'changeDefaultWorkflowEditorToLegacy',
				packageName: 'jiraWorkflowEditorPreferenceSettings',
			},
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			error: error as Error,
		});

		throw error;
	}
};

export const changeDefaultWorkflowEditorToNew = async (
	analyticsEvent: UIAnalyticsEvent,
	workflowPublicationStatus: WorkflowPublicationStatus,
) => {
	try {
		await setGlobalScopeUserPreference({
			key: 'jira.user.workflow.editor.global.scope.enrolment',
			value: NEW_EDITOR,
		});

		fireTrackAnalytics(analyticsEvent, 'newWorkflowEditorUserPreference set', {
			workflowPublicationStatus:
				workflowPublicationStatus === WorkflowPublicationStatus.Dirty
					? 'Unsaved' // user-friendly term to use instead of Dirty
					: WorkflowPublicationStatus[workflowPublicationStatus],
		});
	} catch (error) {
		fireErrorAnalytics({
			meta: {
				id: 'changeDefaultWorkflowEditorToNew',
				packageName: 'jiraWorkflowEditorPreferenceSettings',
			},
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			error: error as Error,
		});

		throw error;
	}
};
