import type { ComponentType } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { composeLayouts, type Layout } from '@atlassian/jira-route-layout';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LegacyAsyncModals = lazyAfterPaint(
	() => import(/* webpackChunkName: "legacy-async-modals" */ './ui/legacy-async-modals'),
	{ ssr: false },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const prioritiesMappingComponents: ComponentType<Record<any, any>>[] = [LegacyAsyncModals];

export const priorityMappingGlobalSettingsLayout: Layout = composeLayouts(globalSettingsLayout, {
	globalComponents: prioritiesMappingComponents,
});
