import { globalLayout } from '@atlassian/jira-global-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarFilters from '@atlassian/jira-navigation-apps-sidebar-filters/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { legacyGinActionRoute } from '@atlassian/jira-router-routes-legacy-gin-action-routes';

export const legacyGinActionRouteEntry = createEntry(legacyGinActionRoute, {
	layout: globalLayout,

	navigation: {
		menuId: MENU_ID.FILTERS,
		sidebar: AsyncSidebarFilters,
	},

	resources: [navigationSidebarGlobalResource],
});
