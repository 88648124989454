import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationSidebarProductsSettingResource } from '@atlassian/jira-router-resources-navigation';
import { globalSettingsProductsDvcsBitbucketRoute } from '@atlassian/jira-router-routes-legacy-global-settings-products-dvcs-bitbucket-routes';

export const globalSettingsProductsDvcsBitbucketRouteEntry = createEntry(
	globalSettingsProductsDvcsBitbucketRoute,
	{
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			// NOTE: the sidebar is rendered for this in Nav V2 too, but there's no corresponding sidebar item?
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

		resources: getNavigationSidebarProductsSettingResource(),
	},
);
