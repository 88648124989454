import {
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_TYPES,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_TYPES_ASSOCIATE,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const legacyProjectSettingsIssueTypesRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_TYPES,
	path: '/plugins/servlet/project-config/:projectKey/issuetypes',
};

export const legacyProjectSettingsIssueTypesAssociateRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_TYPES_ASSOCIATE,
	path: '/secure/admin/SelectIssueTypeSchemeForProject!default.jspa',
};
