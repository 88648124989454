import { globalLayout } from '@atlassian/jira-global-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { legacyDashboardsRestoreRoute } from '@atlassian/jira-router-routes-legacy-dashboards-restore-routes';

export const legacyDashboardsRestoreRouteEntry = createEntry(legacyDashboardsRestoreRoute, {
	layout: globalLayout,

	navigation: {
		menuId: MENU_ID.DASHBOARDS,
	},
});
