import {
	ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_MAIL_INCOMING_MAIL,
	ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_MAIL_DELETE_MAIL_HANDLER,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsSystemMailIncomingRoute = {
	name: ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_MAIL_INCOMING_MAIL,
	path: '/secure/admin/IncomingMailServers.jspa',
};

export const globalSettingsSystemMailDeleteMailHandlerRoute = {
	name: ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_MAIL_DELETE_MAIL_HANDLER,
	path: '/secure/admin/DeleteMailHandler.jspa',
};
