import { ROUTE_GROUPS_LEGACY } from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/jira-router';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { LegacyPage } from '@atlassian/jira-router-routes-common';
import { addonsEmceePublicRouteEntry } from '@atlassian/jira-router-routes-legacy-addons-emcee-public-entries';
import { legacyAllReportsRouteEntry } from '@atlassian/jira-router-routes-legacy-all-reports-entries';
import { legacyBacklogRouteEntry } from '@atlassian/jira-router-routes-legacy-backlog-entries';
import {
	legacyBoardRouteEntry,
	legacyBoard1RouteEntry,
} from '@atlassian/jira-router-routes-legacy-board-entries';
import { legacyConnectGeneralRouteEntry } from '@atlassian/jira-router-routes-legacy-connect-general-entries';
import { legacyConnectProjectRouteEntry } from '@atlassian/jira-router-routes-legacy-connect-project-entries';
import { legacyCustomReportRouteEntry } from '@atlassian/jira-router-routes-legacy-custom-report-entries';
import { legacyDashboardsAddRouteEntry } from '@atlassian/jira-router-routes-legacy-dashboards-add-entries';
import { legacyDashboardsConfigureRouteEntry } from '@atlassian/jira-router-routes-legacy-dashboards-configure-entries';
import { legacyDashboardsDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-dashboards-delete-entries';
import { dashboardsDirectoryRouteEntry } from '@atlassian/jira-router-routes-legacy-dashboards-directory-entries';
import { legacyDashboardsEditRouteEntry } from '@atlassian/jira-router-routes-legacy-dashboards-edit-entries';
import { legacyDashboardsRestoreRouteEntry } from '@atlassian/jira-router-routes-legacy-dashboards-restore-entries';
import { legacyFallbackRouteEntry } from '@atlassian/jira-router-routes-legacy-fallback-entries';
import { legacyGinActionRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-action-entries';
import { legacyGinBulkRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-bulk-entries';
import { legacyGinBulkeditRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-bulkedit-entries';
import { legacyGinIssueAssignRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-issue-assign-entries';
import { legacyGinIssueCloneRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-issue-clone-entries';
import { legacyGinIssueConvertRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-issue-convert-entries';
import { legacyGinIssueCreateRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-issue-create-entries';
import { legacyGinIssueEditRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-issue-edit-entries';
import { legacyGinIssueMoveRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-issue-move-entries';
import { legacyGinManageRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-manage-entries';
import { legacyGinSubscriptionEditRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-subscription-edit-entries';
import { legacyGinSubscriptionsRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-subscriptions-entries';
import { legacyGinSubtaskActionRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-subtask-action-entries';
import { legacyGinWatchersIssueRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-watchers-issue-entries';
import { globalAutomationRouteEntry } from '@atlassian/jira-router-routes-legacy-global-automation-entries';
import {
	globalIssueNavigatorBrowseRouteEntry,
	globalIssueNavigatorRedirectRouteEntry,
	globalIssueNavigatorRouteEntry,
} from '@atlassian/jira-router-routes-legacy-global-issue-navigator-entries';
import { globalSettingsAddonsOauthRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-addons-oauth-entries';
import {
	emceeAppRouteEntry,
	manageAppsRouteEntry,
} from '@atlassian/jira-router-routes-legacy-global-settings-apps-entries';
import { globalSettingsCustomFieldsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-custom-fields-entries';
import { globalSettingsFieldLayoutSchemesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-field-layout-schemes-entries';
import { globalSettingsFieldLayoutsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-field-layouts-entries';
import { globalSettingsIssueSubtasksRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issue-subtasks-entries';
import { globalSettingsIssueTimeTrackingRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issue-time-tracking-entries';
import { globalSettingsIssueWorkflowRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issue-workflow-entries';
import { globalSettingsIssueWorkflowSchemesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issue-workflow-schemes-entries';
import { globalSettingsIssuesCustomFieldsAssociateRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-custom-fields-associate-entries';
import { globalSettingsIssuesCustomFieldsConfigRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-custom-fields-config-entries';
import { globalSettingsIssuesCustomFieldsDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-custom-fields-delete-entries';
import { globalSettingsIssuesCustomFieldsEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-custom-fields-edit-entries';
import { globalSettingsIssuesCustomFieldsManageRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-custom-fields-manage-entries';
import { globalSettingsIssuesCustomFieldsTranslateRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-custom-fields-translate-entries';
import { globalSettingsIssuesFieldAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-add-entries';
import { globalSettingsIssuesFieldConfigureEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-configure-edit-entries';
import { globalSettingsIssuesFieldConfigureRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-configure-entries';
import { globalSettingsIssuesFieldCopyRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-copy-entries';
import { globalSettingsIssuesFieldDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-delete-entries';
import { globalSettingsIssuesFieldEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-edit-entries';
import { globalSettingsIssuesFieldSchemesAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-schemes-add-entries';
import { globalSettingsIssuesFieldSchemesConfigureRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-schemes-configure-entries';
import { globalSettingsIssuesFieldSchemesCopyRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-schemes-copy-entries';
import { globalSettingsIssuesFieldSchemesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-schemes-delete-entries';
import { globalSettingsIssuesFieldSchemesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-schemes-edit-entries';
import { globalSettingsIssuesFieldSchemesEditItemRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-schemes-edit-item-entries';
import { globalSettingsIssuesFieldViewRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-view-entries';
import { globalSettingsIssuesFieldlayoutsConfigRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-config-entries';
import { globalSettingsIssuesFieldlayoutsCopyRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-copy-entries';
import { globalSettingsIssuesFieldlayoutsDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-delete-entries';
import { globalSettingsIssuesFieldlayoutsEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-edit-entries';
import { globalSettingsIssuesFieldlayoutsSchemesConfigRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-schemes-config-entries';
import { globalSettingsIssuesFieldlayoutsSchemesCopyRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-schemes-copy-entries';
import { globalSettingsIssuesFieldlayoutsSchemesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-schemes-delete-entries';
import { globalSettingsIssuesFieldlayoutsSchemesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-schemes-edit-entries';
import { globalSettingsIssuesFieldlayoutsSchemesEntityRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-schemes-entity-entries';
import { globalSettingsIssuesFieldviewRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldview-entries';
import { globalSettingsIssuesGeneralTranslationsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-general-translations-entries';
import {
	globalSettingsIssuesLinkTypesActivateRouteEntry,
	globalSettingsIssuesLinkTypesDeactivateRouteEntry,
	globalSettingsIssuesLinkTypesRouteEntry,
} from '@atlassian/jira-router-routes-legacy-global-settings-issues-link-types-entries';
import { globalSettingsIssuesLinktypesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-linktypes-delete-entries';
import { globalSettingsIssuesLinktypesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-linktypes-edit-entries';
import { globalSettingsIssuesNotificationAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-notification-add-entries';
import { globalSettingsIssuesNotificationDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-notification-delete-entries';
import { globalSettingsIssuesNotificationEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-notification-edit-entries';
import { globalSettingsIssuesNotificationSchemeAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-notification-scheme-add-entries';
import { globalSettingsIssuesNotificationSchemeDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-notification-scheme-delete-entries';
import { globalSettingsIssuesNotificationSchemeEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-notification-scheme-edit-entries';
import { globalSettingsIssuesNotificationViewRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-notification-view-entries';
import { globalSettingsIssuesPermissionsEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-permissions-edit-entries';
import { globalSettingsIssuesPermissionsSchemesAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-permissions-schemes-add-entries';
import { globalSettingsIssuesPermissionsSchemesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-permissions-schemes-delete-entries';
import { globalSettingsIssuesPermissionsSchemesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-permissions-schemes-edit-entries';
import { globalSettingsIssuesPermissionsSchemesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-permissions-schemes-entries';
import { globalSettingsIssuesPermissionsSchemesProjectRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-permissions-schemes-project-entries';
import { globalSettingsIssuesPrioritiesDefaultRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-priorities-default-entries';
import { globalSettingsIssuesPrioritiesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-priorities-delete-entries';
import { globalSettingsIssuesPrioritiesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-priorities-edit-entries';
import { globalSettingsIssuesPrioritiesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-priorities-entries';
import { globalSettingsIssuesPrioritiesTranslationsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-priorities-translations-entries';
import { globalSettingsIssuesResolutionsDefaultRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-resolutions-default-entries';
import { globalSettingsIssuesResolutionsDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-resolutions-delete-entries';
import { globalSettingsIssuesResolutionsEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-resolutions-edit-entries';
import { globalSettingsIssuesResolutionsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-resolutions-entries';
import { globalSettingsIssuesResolutionsTranslationsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-resolutions-translations-entries';
import { globalSettingsIssuesSecurityEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-edit-entries';
import { globalSettingsIssuesSecurityLevelAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-level-add-entries';
import { globalSettingsIssuesSecurityLevelDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-level-delete-entries';
import { globalSettingsIssuesSecurityLevelEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-level-edit-entries';
import { globalSettingsIssuesSecuritySchemesAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-schemes-add-entries';
import { globalSettingsIssuesSecuritySchemesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-schemes-delete-entries';
import { globalSettingsIssuesSecuritySchemesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-schemes-edit-entries';
import { globalSettingsIssuesSecuritySchemesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-schemes-entries';
import { globalSettingsIssuesStatusesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-statuses-entries';
import { globalSettingsIssuesStatusesTranslationsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-statuses-translations-entries';
import { globalSettingsIssuesSubtasksDisableRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-subtasks-disable-entries';
import { globalSettingsIssuesSubtasksEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-subtasks-edit-entries';
import { globalSettingsIssuesSubtasksTranlationsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-subtasks-tranlations-entries';
import { globalSettingsIssuesTypesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-delete-entries';
import { globalSettingsIssuesTypesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-edit-entries';
import { globalSettingsIssuesTypesMigrateRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-migrate-entries';
import { globalSettingsIssuesTypesSchemesAssociateDefaultRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-schemes-associate-default-entries';
import { globalSettingsIssuesTypesSchemesAssociateRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-schemes-associate-entries';
import { globalSettingsIssuesTypesSchemesOptionDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-schemes-option-delete-entries';
import { globalSettingsIssuesTypesSchemesOptionRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-schemes-option-entries';
import { globalSettingsIssuesTypesScreenSchemesConfigureRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-screen-schemes-configure-entries';
import { globalSettingsIssuesTypesScreenSchemesCopyRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-screen-schemes-copy-entries';
import { globalSettingsIssuesTypesScreenSchemesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-screen-schemes-delete-entries';
import { globalSettingsIssuesTypesScreenSchemesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-screen-schemes-edit-entries';
import { globalSettingsIssuesTypesScreenSchemesEntityRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-screen-schemes-entity-entries';
import { globalSettingsIssuesTypesTranslationsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-translations-entries';
import {
	globalSettingsIssuesWorkflowTransitionRouteEntry,
	globalSettingsIssuesWorkflowTransitionMetaAttributesRouteEntry,
} from '@atlassian/jira-router-routes-legacy-global-settings-issues-workflow-transition-entries';
import { globalSettingsIssuesWorkflowsImportRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-workflows-import-entries';
import { globalSettingsIssuesWorkflowsSchemesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-workflows-schemes-edit-entries';
import { globalSettingsProductsAccessRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-access-entries';
import { globalSettingsProductsAdvancedRoadmapsHierarchyRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-advanced-roadmaps-hierarchy-entries';
import { globalSettingsProductsApplinksRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-applinks-entries';
import { globalSettingsProductsDvcsBitbucketRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-dvcs-bitbucket-entries';
import { globalSettingsProductsDvcsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-dvcs-entries';
import { globalSettingsProductsJsdStatusRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-jsd-status-entries';
import { globalSettingsProductsSdconfigRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-sdconfig-entries';
import { globalSettingsProductsSdmailRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-sdmail-entries';
import { globalSettingsProductsSoftwareConfigRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-software-config-entries';
import { globalSettingsProjectsCategoriesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-projects-categories-delete-entries';
import { globalSettingsProjectsCategoriesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-projects-categories-edit-entries';
import { globalSettingsProjectsCategoriesViewRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-projects-categories-view-entries';
import { globalSettingsSystemApplicationAdvancedRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-application-advanced-entries';
import { globalSettingsSystemApplicationEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-application-edit-entries';
import { globalSettingsSystemApplicationViewRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-application-view-entries';
import { globalSettingsSystemAttachmentsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-attachments-entries';
import { globalSettingsSystemAuditingRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-auditing-entries';
import { globalSettingsSystemCollectorsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-collectors-entries';
import { globalSettingsSystemDefaultSettingsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-default-settings-entries';
import {
	globalSettingsSystemEventsRouteEntry,
	globalSettingsSystemEventsAddRouteEntry,
	globalSettingsSystemEventsDeleteRouteEntry,
	globalSettingsSystemEventsEditRouteEntry,
} from '@atlassian/jira-router-routes-legacy-global-settings-system-events-entries';
import { globalSettingsSystemExportRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-export-entries';
import { globalSettingsSystemGlobalPermissionsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-global-permissions-entries';
import { globalSettingsSystemHelperNotificationRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-helper-notification-entries';
import { globalSettingsSystemHelperPermissionRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-helper-permission-entries';
import { globalSettingsSystemImportBulkRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-import-bulk-entries';
import { globalSettingsSystemImportCloudRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-import-cloud-entries';
import { globalSettingsSystemImportCsvRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-import-csv-entries';
import { globalSettingsSystemImportExternalRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-import-external-entries';
import { globalSettingsSystemImportExternalPagesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-import-external-pages-entries';
import { globalSettingsSystemImportJsonRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-import-json-entries';
import { globalSettingsSystemImportTrelloRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-import-trello-entries';
import { globalSettingsSystemLexorankRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-lexorank-entries';
import { globalSettingsSystemLookRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-look-entries';
import { globalSettingsSystemMailAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-mail-add-entries';
import {
	globalSettingsSystemMailIncomingRouteEntry,
	globalSettingsSystemMailDeleteMailHandlerRouteEntry,
} from '@atlassian/jira-router-routes-legacy-global-settings-system-mail-incoming-entries';
import { globalSettingsSystemMailOutgoingRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-mail-outgoing-entries';
import { globalSettingsSystemMailSendRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-mail-send-entries';
import { globalSettingsSystemMailSettingsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-mail-settings-entries';
import { globalSettingsSystemMailVerifyRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-mail-verify-entries';
import { globalSettingsSystemPermissionsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-permissions-entries';
import { globalSettingsSystemProjectAssignSecurityRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-project-assign-security-entries';
import { globalSettingsSystemProjectDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-project-delete-entries';
import { globalSettingsSystemProjectSelectRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-project-select-entries';
import { globalSettingsSystemRolesActorsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-actors-entries';
import { globalSettingsSystemRolesActorsGroupsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-actors-groups-entries';
import { globalSettingsSystemRolesActorsUsersRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-actors-users-entries';
import { globalSettingsSystemRolesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-delete-entries';
import { globalSettingsSystemRolesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-edit-entries';
import { globalSettingsSystemRolesUsageRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-usage-entries';
import { globalSettingsSystemRolesViewRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-view-entries';
import { globalSettingsSystemServicesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-services-entries';
import { globalSettingsSystemSharedDashboardsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-shared-dashboards-entries';
import { globalSettingsSystemSharedFiltersRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-shared-filters-entries';
import { globalSettingsSystemUserInterfaceDashboardEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-user-interface-dashboard-edit-entries';
import { globalSettingsSystemUserInterfacePreferencesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-user-interface-preferences-entries/src';
import { globalSettingsSystemWebhooksRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-webhooks-entries';
import { legacyIssueRouteEntry } from '@atlassian/jira-router-routes-legacy-issue-entries';
import { legacyJsdCustomersRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-customers-entries';
import { legacyJsdGettingStartedRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-getting-started-entries';
import { legacyJsdJiraAutomationRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-jira-automation-entries';
import { legacyJsdKnowledgeRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-knowledge-entries';
import { legacyJsdQueuesRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-queues-entries';
import { legacyJsdQueuesManageRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-queues-manage-entries';
import { legacyJsdReportsCustomRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-reports-custom-entries';
import { legacyJsdReportsRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-reports-entries';
import { legacyJsdSettingsFallbackRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-settings-fallback-entries';
import { legacyJsdSettingsLanguageRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-settings-language-entries';
import { legacyJsdSettingsLanguagePagesRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-settings-language-pages-entries';
import { legacyJsdWidgetsRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-widgets-entries';
import { legacyPersonalSettingsAccessTokensRouteEntry } from '@atlassian/jira-router-routes-legacy-personal-settings-access-tokens-entries';
import { legacyPinRouteEntry } from '@atlassian/jira-router-routes-legacy-pin-entries';
import { legacyPortfolioPagesRouteEntry } from '@atlassian/jira-router-routes-legacy-portfolio-pages-entries';
import { legacyPortfolioTeamRouteEntry } from '@atlassian/jira-router-routes-legacy-portfolio-team-entries';
import { legacyProjectReleasesBambooRouteEntry } from '@atlassian/jira-router-routes-legacy-project-releases-bamboo-entries';
import { legacyProjectReleasesRouteEntry } from '@atlassian/jira-router-routes-legacy-project-releases-entries';
import { legacyProjectReleasesNoteConfigRouteEntry } from '@atlassian/jira-router-routes-legacy-project-releases-note-config-entries';
import { legacyProjectReleasesNoteRouteEntry } from '@atlassian/jira-router-routes-legacy-project-releases-note-entries';
import { legacyProjectSettingsCollectorsAddRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-collectors-add-entries';
import { legacyProjectSettingsCollectorsRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-collectors-entries';
import { legacyProjectSettingsCollectorsHelpRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-collectors-help-entries';
import { legacyProjectSettingsCollectorsViewRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-collectors-view-entries';
import { legacyProjectSettingsConfigRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-config-entries';
import { legacyProjectSettingsDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-delete-entries';
import { legacyProjectSettingsDevRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-dev-entries';
import { legacyProjectSettingsEditRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-edit-entries';
import {
	legacyProjectSettingsFieldsRouteEntry,
	legacyProjectSettingsFieldsAssociateRouteEntry,
} from '@atlassian/jira-router-routes-legacy-project-settings-fields-entries';
import { legacyProjectSettingsIssueLayoutRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-issue-layout-entries';
import {
	legacyProjectSettingsIssueSecurityRouteEntry,
	legacyProjectSettingsIssueSecurityAssociateRouteEntry,
} from '@atlassian/jira-router-routes-legacy-project-settings-issue-security-entries';
import {
	legacyProjectSettingsIssueTypesRouteEntry,
	legacyProjectSettingsIssueTypesAssociateRouteEntry,
} from '@atlassian/jira-router-routes-legacy-project-settings-issue-types-entries';
import {
	legacyProjectSettingsNotificationsRouteEntry,
	legacyProjectSettingsNotificationsAssociateRouteEntry,
} from '@atlassian/jira-router-routes-legacy-project-settings-notifications-entries';
import { legacyProjectSettingsOpsgenieRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-opsgenie-entries';
import { legacyProjectSettingsPeopleRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-people-entries';
import { legacyProjectSettingsPermissionsRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-permissions-entries';
import {
	legacyProjectSettingsScreensRouteEntry,
	legacyProjectSettingsScreensAssociateRouteEntry,
} from '@atlassian/jira-router-routes-legacy-project-settings-screens-entries';
import { legacyProjectSettingsSummaryRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-summary-entries';
import {
	legacyProjectSettingsWorkflowsRouteEntry,
	legacyProjectSettingsWorkflowsAssociateRouteEntry,
} from '@atlassian/jira-router-routes-legacy-project-settings-workflows-entries';
import { legacyReportsAverageAgeRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-average-age-entries';
import { legacyReportsConfigRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-config-entries';
import { legacyReportsCreatedVsResolvedRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-created-vs-resolved-entries';
import { legacyReportsDeveloperWorkloadRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-developer-workload-entries';
import { legacyReportsPieRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-pie-entries';
import { legacyReportsRecentlyCreatedRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-recently-created-entries';
import { legacyReportsResolutionTimeRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-resolution-time-entries';
import { legacyReportsSingleLevelGroupByRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-single-level-group-by-entries';
import { legacyReportsTimeSinceRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-time-since-entries';
import { legacyReportsTimeTrackingReportRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-time-tracking-report-entries';
import { legacyReportsVersionWorkloadRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-version-workload-entries';
import { legacyReportsWorkloadPieRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-workload-pie-entries';
import { workflowDesignerRouteEntry } from '@atlassian/jira-router-routes-legacy-workflow-designer-entries';

type PartialRoute = Omit<Route, 'component' | 'forPaint' | 'preloadOptions'> & {
	component?: Route['component'];
	forPaint?: Route['forPaint'];
	preloadOptions?: Route['preloadOptions'];
};

/*
    These routes are only used when the SPA route didn't hit in backend,
    and it rendered navigation-skeleton bundle not jira-spa bundle. This means these route are not used in SPA.
    Please delete these routes if your legacy page is not used anymore.
 */
const getRawRoutes = (): PartialRoute[] => [
	legacyJsdGettingStartedRouteEntry,
	legacyJsdCustomersRouteEntry,
	legacyJsdKnowledgeRouteEntry,
	legacyJsdQueuesManageRouteEntry,
	legacyJsdQueuesRouteEntry,
	legacyJsdReportsCustomRouteEntry,
	legacyJsdReportsRouteEntry,
	legacyJsdSettingsLanguageRouteEntry,
	legacyJsdSettingsLanguagePagesRouteEntry,
	legacyJsdJiraAutomationRouteEntry,
	legacyJsdWidgetsRouteEntry,
	legacyJsdSettingsFallbackRouteEntry,
	legacyCustomReportRouteEntry,
	legacyBacklogRouteEntry,
	legacyBoardRouteEntry,
	legacyBoard1RouteEntry,
	legacyAllReportsRouteEntry,
	globalIssueNavigatorRouteEntry,
	globalIssueNavigatorBrowseRouteEntry,
	globalIssueNavigatorRedirectRouteEntry,
	legacyIssueRouteEntry,
	legacyPinRouteEntry,
	legacyProjectReleasesRouteEntry,
	legacyProjectReleasesNoteRouteEntry,
	legacyProjectReleasesNoteConfigRouteEntry,
	legacyProjectReleasesBambooRouteEntry,
	legacyReportsAverageAgeRouteEntry,
	legacyReportsCreatedVsResolvedRouteEntry,
	legacyReportsPieRouteEntry,
	legacyReportsRecentlyCreatedRouteEntry,
	legacyReportsResolutionTimeRouteEntry,
	legacyReportsSingleLevelGroupByRouteEntry,
	legacyReportsTimeSinceRouteEntry,
	legacyReportsTimeTrackingReportRouteEntry,
	legacyReportsDeveloperWorkloadRouteEntry,
	legacyReportsVersionWorkloadRouteEntry,
	legacyReportsWorkloadPieRouteEntry,
	legacyReportsConfigRouteEntry,
	legacyConnectProjectRouteEntry,
	legacyProjectSettingsPeopleRouteEntry,
	legacyProjectSettingsSummaryRouteEntry,
	legacyProjectSettingsIssueTypesRouteEntry,
	legacyProjectSettingsIssueTypesAssociateRouteEntry,
	legacyProjectSettingsIssueLayoutRouteEntry,
	legacyProjectSettingsWorkflowsRouteEntry,
	legacyProjectSettingsWorkflowsAssociateRouteEntry,
	legacyProjectSettingsScreensRouteEntry,
	legacyProjectSettingsScreensAssociateRouteEntry,
	legacyProjectSettingsFieldsRouteEntry,
	legacyProjectSettingsFieldsAssociateRouteEntry,
	legacyProjectSettingsOpsgenieRouteEntry,
	legacyProjectSettingsPermissionsRouteEntry,
	legacyProjectSettingsIssueSecurityRouteEntry,
	legacyProjectSettingsIssueSecurityAssociateRouteEntry,
	legacyProjectSettingsNotificationsRouteEntry,
	legacyProjectSettingsNotificationsAssociateRouteEntry,
	legacyProjectSettingsConfigRouteEntry,
	legacyProjectSettingsEditRouteEntry,
	legacyProjectSettingsDeleteRouteEntry,
	legacyProjectSettingsCollectorsRouteEntry,
	legacyProjectSettingsCollectorsAddRouteEntry,
	legacyProjectSettingsCollectorsHelpRouteEntry,
	legacyProjectSettingsCollectorsViewRouteEntry,
	legacyProjectSettingsDevRouteEntry,
	legacyDashboardsConfigureRouteEntry,
	dashboardsDirectoryRouteEntry,
	legacyDashboardsAddRouteEntry,
	legacyDashboardsEditRouteEntry,
	legacyDashboardsDeleteRouteEntry,
	legacyDashboardsRestoreRouteEntry,
	legacyGinManageRouteEntry,
	legacyGinBulkeditRouteEntry,
	legacyGinBulkRouteEntry,
	legacyGinIssueMoveRouteEntry,
	legacyGinSubtaskActionRouteEntry,
	legacyGinIssueCloneRouteEntry,
	legacyGinIssueAssignRouteEntry,
	legacyGinIssueCreateRouteEntry,
	legacyGinIssueConvertRouteEntry,
	legacyGinIssueEditRouteEntry,
	legacyGinWatchersIssueRouteEntry,
	legacyGinActionRouteEntry,
	legacyGinSubscriptionsRouteEntry,
	legacyGinSubscriptionEditRouteEntry,
	legacyPortfolioPagesRouteEntry,
	legacyPortfolioTeamRouteEntry,
	globalSettingsSystemApplicationViewRouteEntry,
	globalSettingsSystemApplicationAdvancedRouteEntry,
	globalSettingsSystemApplicationEditRouteEntry,
	globalSettingsSystemAuditingRouteEntry,
	globalSettingsSystemRolesViewRouteEntry,
	globalSettingsSystemRolesUsageRouteEntry,
	globalSettingsSystemRolesActorsRouteEntry,
	globalSettingsSystemRolesActorsGroupsRouteEntry,
	globalSettingsSystemRolesActorsUsersRouteEntry,
	globalSettingsSystemRolesEditRouteEntry,
	globalSettingsSystemRolesDeleteRouteEntry,
	globalSettingsSystemProjectDeleteRouteEntry,
	globalSettingsSystemProjectAssignSecurityRouteEntry,
	globalSettingsSystemPermissionsRouteEntry,
	globalSettingsSystemCollectorsRouteEntry,
	globalSettingsSystemDefaultSettingsRouteEntry,
	globalSettingsSystemLookRouteEntry,
	globalSettingsSystemExportRouteEntry,
	globalSettingsSystemGlobalPermissionsRouteEntry,
	globalSettingsSystemImportExternalRouteEntry,
	globalSettingsSystemImportExternalPagesRouteEntry,
	globalSettingsSystemImportCsvRouteEntry,
	globalSettingsSystemImportJsonRouteEntry,
	globalSettingsSystemImportTrelloRouteEntry,
	globalSettingsSystemImportCloudRouteEntry,
	globalSettingsSystemImportBulkRouteEntry,
	globalAutomationRouteEntry,
	globalSettingsSystemMailSettingsRouteEntry,
	globalSettingsSystemMailOutgoingRouteEntry,
	globalSettingsSystemMailDeleteMailHandlerRouteEntry,
	globalSettingsSystemMailIncomingRouteEntry,
	globalSettingsSystemMailAddRouteEntry,
	globalSettingsSystemMailVerifyRouteEntry,
	globalSettingsSystemMailSendRouteEntry,
	globalSettingsSystemHelperPermissionRouteEntry,
	globalSettingsSystemHelperNotificationRouteEntry,
	globalSettingsSystemSharedFiltersRouteEntry,
	globalSettingsSystemSharedDashboardsRouteEntry,
	globalSettingsSystemAttachmentsRouteEntry,
	globalSettingsSystemEventsRouteEntry,
	globalSettingsSystemEventsAddRouteEntry,
	globalSettingsSystemEventsDeleteRouteEntry,
	globalSettingsSystemEventsEditRouteEntry,
	globalSettingsSystemUserInterfaceDashboardEditRouteEntry,
	globalSettingsSystemUserInterfacePreferencesRouteEntry,
	globalSettingsSystemWebhooksRouteEntry,
	globalSettingsSystemServicesRouteEntry,
	globalSettingsSystemLexorankRouteEntry,
	globalSettingsProductsAccessRouteEntry,
	globalSettingsProductsSoftwareConfigRouteEntry,
	globalSettingsProductsAdvancedRoadmapsHierarchyRouteEntry,
	globalSettingsProductsSdconfigRouteEntry,
	globalSettingsProductsSdmailRouteEntry,
	globalSettingsProductsApplinksRouteEntry,
	globalSettingsProductsDvcsRouteEntry,
	globalSettingsProductsDvcsBitbucketRouteEntry,
	globalSettingsProductsJsdStatusRouteEntry,
	globalSettingsProjectsCategoriesViewRouteEntry,
	globalSettingsProjectsCategoriesEditRouteEntry,
	globalSettingsProjectsCategoriesDeleteRouteEntry,
	globalSettingsIssuesTypesEditRouteEntry,
	globalSettingsIssuesTypesDeleteRouteEntry,
	globalSettingsIssuesTypesMigrateRouteEntry,
	globalSettingsIssuesTypesTranslationsRouteEntry,
	globalSettingsIssuesTypesSchemesOptionRouteEntry,
	globalSettingsIssuesTypesSchemesAssociateDefaultRouteEntry,
	globalSettingsIssuesTypesSchemesAssociateRouteEntry,
	globalSettingsIssuesTypesSchemesOptionDeleteRouteEntry,
	globalSettingsIssueSubtasksRouteEntry,
	globalSettingsIssuesSubtasksDisableRouteEntry,
	globalSettingsIssuesSubtasksEditRouteEntry,
	globalSettingsIssuesSubtasksTranlationsRouteEntry,
	workflowDesignerRouteEntry,
	globalSettingsIssueWorkflowRouteEntry,
	globalSettingsIssuesWorkflowTransitionRouteEntry,
	globalSettingsIssuesWorkflowTransitionMetaAttributesRouteEntry,
	globalSettingsIssuesWorkflowsImportRouteEntry,
	globalSettingsIssueWorkflowSchemesRouteEntry,
	globalSettingsIssuesWorkflowsSchemesEditRouteEntry,
	globalSettingsIssuesFieldViewRouteEntry,
	globalSettingsIssuesFieldAddRouteEntry,
	globalSettingsIssuesFieldConfigureRouteEntry,
	globalSettingsIssuesFieldConfigureEditRouteEntry,
	globalSettingsIssuesFieldEditRouteEntry,
	globalSettingsIssuesFieldCopyRouteEntry,
	globalSettingsIssuesFieldDeleteRouteEntry,
	globalSettingsIssuesFieldSchemesAddRouteEntry,
	globalSettingsIssuesFieldSchemesConfigureRouteEntry,
	globalSettingsIssuesFieldSchemesEditRouteEntry,
	globalSettingsIssuesFieldSchemesEditItemRouteEntry,
	globalSettingsIssuesFieldSchemesCopyRouteEntry,
	globalSettingsIssuesFieldSchemesDeleteRouteEntry,
	globalSettingsIssuesTypesScreenSchemesConfigureRouteEntry,
	globalSettingsIssuesTypesScreenSchemesEntityRouteEntry,
	globalSettingsIssuesTypesScreenSchemesEditRouteEntry,
	globalSettingsIssuesTypesScreenSchemesCopyRouteEntry,
	globalSettingsIssuesTypesScreenSchemesDeleteRouteEntry,
	globalSettingsCustomFieldsRouteEntry,
	globalSettingsIssuesCustomFieldsConfigRouteEntry,
	globalSettingsIssuesCustomFieldsEditRouteEntry,
	globalSettingsIssuesCustomFieldsTranslateRouteEntry,
	globalSettingsIssuesCustomFieldsDeleteRouteEntry,
	globalSettingsIssuesCustomFieldsManageRouteEntry,
	globalSettingsIssuesCustomFieldsAssociateRouteEntry,
	globalSettingsFieldLayoutsRouteEntry,
	globalSettingsIssuesFieldlayoutsConfigRouteEntry,
	globalSettingsIssuesFieldviewRouteEntry,
	globalSettingsIssuesFieldlayoutsCopyRouteEntry,
	globalSettingsIssuesFieldlayoutsEditRouteEntry,
	globalSettingsIssuesFieldlayoutsDeleteRouteEntry,
	globalSettingsFieldLayoutSchemesRouteEntry,
	globalSettingsIssuesFieldlayoutsSchemesConfigRouteEntry,
	globalSettingsIssuesFieldlayoutsSchemesEntityRouteEntry,
	globalSettingsIssuesFieldlayoutsSchemesCopyRouteEntry,
	globalSettingsIssuesFieldlayoutsSchemesEditRouteEntry,
	globalSettingsIssuesFieldlayoutsSchemesDeleteRouteEntry,
	globalSettingsIssueTimeTrackingRouteEntry,
	globalSettingsIssuesLinkTypesRouteEntry,
	globalSettingsIssuesLinkTypesActivateRouteEntry,
	globalSettingsIssuesLinkTypesDeactivateRouteEntry,
	globalSettingsIssuesLinktypesEditRouteEntry,
	globalSettingsIssuesLinktypesDeleteRouteEntry,
	globalSettingsIssuesStatusesRouteEntry,
	globalSettingsIssuesStatusesTranslationsRouteEntry,
	globalSettingsIssuesResolutionsRouteEntry,
	globalSettingsIssuesResolutionsTranslationsRouteEntry,
	globalSettingsIssuesResolutionsDefaultRouteEntry,
	globalSettingsIssuesResolutionsEditRouteEntry,
	globalSettingsIssuesResolutionsDeleteRouteEntry,
	globalSettingsIssuesPrioritiesRouteEntry,
	globalSettingsIssuesPrioritiesTranslationsRouteEntry,
	globalSettingsIssuesGeneralTranslationsRouteEntry,
	globalSettingsIssuesPrioritiesEditRouteEntry,
	globalSettingsIssuesPrioritiesDeleteRouteEntry,
	globalSettingsIssuesPrioritiesDefaultRouteEntry,
	globalSettingsIssuesSecuritySchemesRouteEntry,
	globalSettingsIssuesSecurityEditRouteEntry,
	globalSettingsIssuesSecurityLevelAddRouteEntry,
	globalSettingsIssuesSecurityLevelEditRouteEntry,
	globalSettingsIssuesSecurityLevelDeleteRouteEntry,
	globalSettingsIssuesSecuritySchemesAddRouteEntry,
	globalSettingsIssuesSecuritySchemesEditRouteEntry,
	globalSettingsIssuesSecuritySchemesDeleteRouteEntry,
	globalSettingsIssuesNotificationViewRouteEntry,
	globalSettingsIssuesNotificationSchemeAddRouteEntry,
	globalSettingsIssuesNotificationSchemeEditRouteEntry,
	globalSettingsIssuesNotificationSchemeDeleteRouteEntry,
	globalSettingsIssuesNotificationAddRouteEntry,
	globalSettingsIssuesNotificationEditRouteEntry,
	globalSettingsIssuesNotificationDeleteRouteEntry,
	globalSettingsIssuesPermissionsSchemesRouteEntry,
	globalSettingsIssuesPermissionsSchemesAddRouteEntry,
	globalSettingsIssuesPermissionsEditRouteEntry,
	globalSettingsIssuesPermissionsSchemesEditRouteEntry,
	globalSettingsIssuesPermissionsSchemesProjectRouteEntry,
	globalSettingsIssuesPermissionsSchemesDeleteRouteEntry,
	addonsEmceePublicRouteEntry,
	emceeAppRouteEntry,
	manageAppsRouteEntry,
	globalSettingsAddonsOauthRouteEntry,
	globalSettingsSystemProjectSelectRouteEntry, // this route uses regex, so it should be defined last
	legacyPersonalSettingsAccessTokensRouteEntry,
	legacyConnectGeneralRouteEntry,
	legacyFallbackRouteEntry,
];

export const getStaticRoutes = () =>
	getRawRoutes()
		.filter(({ path }) => path) // Required for static routes webpack plugin due to dynamic paths
		.map(({ group, name, path, query, preloadOptions, meta }) => ({
			group,
			name,
			path,
			query,
			preloadOptions,
			meta,
		}));

export const getRoutes = (): Route[] => {
	const routes = getRawRoutes().map((route: Route) => ({
		...route,
		group: ROUTE_GROUPS_LEGACY,
		component: route.component || LegacyPage,
		resources: [
			...new Set([...getNavigationResources(), ...(route.resources ? route.resources : [])]),
		],
	}));

	return routes;
};
