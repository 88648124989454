import { ROUTE_NAMES_LEGACY_BOARD } from '@atlassian/jira-common-constants/src/spa-routes';

export const legacyBoardRoute = {
	name: ROUTE_NAMES_LEGACY_BOARD,
	path: '/secure/RapidBoard.jspa',
	query: ['rapidView'],
};

export const legacyBoard1Route = {
	name: ROUTE_NAMES_LEGACY_BOARD,
	path: '/secure/RapidBoard.jspa',
};
