import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getNavigationSidebarProjectSettingsResource,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { legacyProjectSettingsConfigRoute } from '@atlassian/jira-router-routes-legacy-project-settings-config-routes';

export const legacyProjectSettingsConfigRouteEntry = createEntry(legacyProjectSettingsConfigRoute, {
	layout: genericProjectLayout,

	navigation: {
		horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		menuId: MENU_ID.PROJECTS,
		sidebar: genericProjectSidebars,
		sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
	},

	resources: [
		...getBusinessProjectResources(),
		...getNavigationSidebarProjectResource(),
		...getNavigationSidebarProjectSettingsResource(),
		getConsolidationStateResource(),
	],
});
