import {
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS_ASSOCIATE,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const legacyProjectSettingsScreensRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS,
	path: '/plugins/servlet/project-config/:projectKey/screens',
};

export const legacyProjectSettingsScreensAssociateRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS_ASSOCIATE,
	path: '/secure/project/SelectIssueTypeScreenScheme!default.jspa',
};
