// like !default / !edit / %21viewLogs
import { ROUTE_NAMES_LEGACY_REPORTS_TIME_TRACKING_REPORT } from '@atlassian/jira-common-constants/src/spa-routes';

const defaultSuffix = '([!%]?[\\w]*)';

export const legacyReportsTimeTrackingReportRoute = {
	name: ROUTE_NAMES_LEGACY_REPORTS_TIME_TRACKING_REPORT,
	path: `/secure/ConfigureReport${defaultSuffix}.jspa`,
	query: ['reportKey=com.atlassian.jira.plugin.system.reports:time-tracking'],
};
