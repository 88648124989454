import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getNavigationSidebarProjectSettingsResource,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { legacyJsdSettingsLanguagePagesRoute } from '@atlassian/jira-router-routes-legacy-jsd-settings-language-pages-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';

export const legacyJsdSettingsLanguagePagesRouteEntry = createEntry(
	legacyJsdSettingsLanguagePagesRoute,
	{
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

		resources: [
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			getConsolidationStateResource(),
		],
	},
);
