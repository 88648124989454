import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationSidebarProjectResource } from '@atlassian/jira-router-resources-navigation';
import { legacyJsdQueuesRoute } from '@atlassian/jira-router-routes-legacy-jsd-queues-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';

export const legacyJsdQueuesRouteEntry = createEntry(legacyJsdQueuesRoute, {
	layout: serviceProjectLayout,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},

	resources: [...getNavigationSidebarProjectResource(), getConsolidationStateResource()],
});
