import {
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS_ASSOCIATE,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const legacyProjectSettingsNotificationsRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS,
	path: '/plugins/servlet/project-config/:projectKey/notifications',
};

export const legacyProjectSettingsNotificationsAssociateRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS_ASSOCIATE,
	path: '/secure/project/SelectProjectScheme!default.jspa',
};
