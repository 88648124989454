import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { globalSettingsSystemUserInterfacePreferencesRoute } from '@atlassian/jira-router-routes-legacy-global-settings-system-user-interface-preferences-routes';

export const globalSettingsSystemUserInterfacePreferencesRouteEntry = createEntry(
	globalSettingsSystemUserInterfacePreferencesRoute,
	{
		layout: globalSettingsLayout,
		resources: [navigationSidebarGlobalResource],

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
		},
	},
);
