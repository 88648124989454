import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import {
	globalSettingsSystemEventsRoute,
	globalSettingsSystemEventsAddRoute,
	globalSettingsSystemEventsDeleteRoute,
	globalSettingsSystemEventsEditRoute,
} from '@atlassian/jira-router-routes-legacy-global-settings-system-events-routes';

export const globalSettingsSystemEventsRouteEntry = createEntry(globalSettingsSystemEventsRoute, {
	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsSystem,
	},

	resources: [navigationSidebarGlobalResource],
});

export const globalSettingsSystemEventsAddRouteEntry = createEntry(
	globalSettingsSystemEventsAddRoute,
	{
		layout: globalSettingsLayout,
		resources: [navigationSidebarGlobalResource],

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
		},
	},
);

export const globalSettingsSystemEventsDeleteRouteEntry = createEntry(
	globalSettingsSystemEventsDeleteRoute,
	{
		layout: globalSettingsLayout,
		resources: [navigationSidebarGlobalResource],

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
		},
	},
);

export const globalSettingsSystemEventsEditRouteEntry = createEntry(
	globalSettingsSystemEventsEditRoute,
	{
		layout: globalSettingsLayout,
		resources: [navigationSidebarGlobalResource],

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
		},
	},
);
