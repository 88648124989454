import { Component } from 'react';
import isEqual from 'lodash/isEqual';
import { calcRapidBoardResetState } from '../../utils';
import type { TriggerProps } from './types';

// eslint-disable-next-line jira/react/no-class-components
export default class CustomTrigger extends Component<TriggerProps> {
	constructor(props: TriggerProps) {
		super(props);
		const [paths] = calcRapidBoardResetState(
			this.rapidBoardPaths,
			this.props.route.name,
			!!this.props.match.query.useStoredSettings,
		);
		this.rapidBoardPaths = paths;
	}

	shouldComponentUpdate(nextProps: TriggerProps) {
		if (!isEqual(this.props.match, nextProps.match)) {
			// RapidboardPaths is being persisted here.
			// resetDone boolean is calculated in the util function.
			const [paths, resetBoolean] = calcRapidBoardResetState(
				this.rapidBoardPaths,
				nextProps.route.name,
				!!nextProps.match.query.useStoredSettings,
			);
			this.rapidBoardPaths = paths;
			this.resetDone = resetBoolean;
		}
		return true;
	}

	componentDidUpdate() {
		if (!this.resetDone) {
			if (!__SPA__) {
				this.resetDone = true;
				return;
			}
			this.props.resetCurrentPage();
			this.resetDone = true;
		}
	}

	resetDone = true;

	rapidBoardPaths: string[] = [];

	render() {
		return null;
	}
}
