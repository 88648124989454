import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { globalSettingsSystemCollectorsRoute } from '@atlassian/jira-router-routes-legacy-global-settings-system-collectors-routes';

export const globalSettingsSystemCollectorsRouteEntry = createEntry(
	globalSettingsSystemCollectorsRoute,
	{
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

		resources: [navigationSidebarGlobalResource],
	},
);
