import { globalLayout } from '@atlassian/jira-global-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { legacyPersonalSettingsAccessTokensRoute } from '@atlassian/jira-router-routes-legacy-personal-settings-access-tokens-routes';

export const legacyPersonalSettingsAccessTokensRouteEntry = createEntry(
	legacyPersonalSettingsAccessTokensRoute,
	{
		layout: globalLayout,
	},
);
