import { getNavigationSidebarGlobalSettingsResources } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { genericConnectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { createLayout } from '@atlassian/jira-route-layout';
import { connectGeneralResources } from '@atlassian/jira-router-resources-connect-general';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getNavigationSidebarProjectSettingsResource,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { legacyConnectGeneralRoute } from '@atlassian/jira-router-routes-legacy-connect-general-routes';

export const legacyConnectGeneralRouteEntry = createEntry(legacyConnectGeneralRoute, {
	layout: createLayout(),

	navigation: {
		sidebar: genericConnectSidebars,
	},

	resources: [
		connectGeneralResources,
		navigationSidebarGlobalResource,
		...getNavigationSidebarGlobalSettingsResources(),
		...getNavigationSidebarProjectResource(),
		...getNavigationSidebarProjectSettingsResource(),
		getConsolidationStateResource(),
	],
});
