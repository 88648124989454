import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationSidebarGlobalSettingsResources } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import AsyncSidebarGlobalSettingsApps from '@atlassian/jira-navigation-apps-sidebar-global-settings-apps/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { globalSettingsAddonsOauthRoute } from '@atlassian/jira-router-routes-legacy-global-settings-addons-oauth-routes';

export const globalSettingsAddonsOauthRouteEntry = createEntry(globalSettingsAddonsOauthRoute, {
	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_APPS_SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsApps,
	},

	resources: [navigationSidebarGlobalResource, ...getNavigationSidebarGlobalSettingsResources()],
});
