import React from 'react';
import type { RouteContext } from '@atlassian/jira-router';
import { LegacyPage } from '@atlassian/jira-router-routes-common/src/ui/legacy-page/main.tsx';

type Props = RouteContext;

/**
 * LegacyIssueView is now deprecated, please do not use this.
 * This will soon be removed, ticket to track: https://jplat.jira.atlassian.cloud/browse/JIV-15935
 */
export const LegacyIssueView = (props: Props) => {
	const { route } = props;

	if (!route) {
		return null;
	}

	return (
		<>
			<LegacyPage {...props} />
		</>
	);
};
