import {
	ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS,
	ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS_ADD,
	ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS_DELETE,
	ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS_EDIT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsSystemEventsRoute = {
	name: ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS,
	path: '/secure/admin/ListEventTypes.jspa',
};

export const globalSettingsSystemEventsAddRoute = {
	name: ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS_ADD,
	path: '/secure/admin/AddEventType.jspa',
};

export const globalSettingsSystemEventsDeleteRoute = {
	name: ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS_DELETE,
	path: '/secure/admin/DeleteEventType.jspa',
};

export const globalSettingsSystemEventsEditRoute = {
	name: ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_ADVANCED_EVENTS_EDIT,
	path: '/secure/admin/EditEventType.jspa',
};
