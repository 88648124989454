import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import {
	globalSettingsIssuesWorkflowTransitionRoute,
	globalSettingsIssuesWorkflowTransitionMetaAttributesRoute,
} from '@atlassian/jira-router-routes-legacy-global-settings-issues-workflow-transition-routes';

export const globalSettingsIssuesWorkflowTransitionRouteEntry = createEntry(
	globalSettingsIssuesWorkflowTransitionRoute,
	{
		layout: globalSettingsLayout,
		resources: [navigationSidebarGlobalResource],

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
		},
	},
);

export const globalSettingsIssuesWorkflowTransitionMetaAttributesRouteEntry = createEntry(
	globalSettingsIssuesWorkflowTransitionMetaAttributesRoute,
	{
		layout: globalSettingsLayout,
		resources: [navigationSidebarGlobalResource],

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
		},
	},
);
