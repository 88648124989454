// like !default / !edit / %21viewLogs
import { ROUTE_NAMES_LEGACY_REPORTS_RECENTLY_CREATED } from '@atlassian/jira-common-constants/src/spa-routes';

const defaultSuffix = '([!%]?[\\w]*)';

export const legacyReportsRecentlyCreatedRoute = {
	name: ROUTE_NAMES_LEGACY_REPORTS_RECENTLY_CREATED,
	path: `/secure/ConfigureReport${defaultSuffix}.jspa`,
	query: ['reportKey=com.atlassian.jira.plugin.system.reports:recentlycreated-report'],
};
