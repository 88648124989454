import {
	ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES,
	ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES_ACTIVATE,
	ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES_DEACTIVATE,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsIssuesLinkTypesRoute = {
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES,
	path: '/secure/admin/ViewLinkTypes!default.jspa',
};

export const globalSettingsIssuesLinkTypesActivateRoute = {
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES_ACTIVATE,
	path: '/secure/admin/IssueLinkingActivate.jspa',
};

export const globalSettingsIssuesLinkTypesDeactivateRoute = {
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_LINK_TYPES_DEACTIVATE,
	path: '/secure/admin/IssueLinkingDeActivate.jspa',
};
