import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationSidebarProjectResource } from '@atlassian/jira-router-resources-navigation';
import { LegacyPage } from '@atlassian/jira-router-routes-common/src/ui/legacy-page/main.tsx';
import {
	legacyBoardRoute,
	legacyBoard1Route,
} from '@atlassian/jira-router-routes-legacy-board-routes';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';

export const legacyBoardRouteEntry = createEntry(legacyBoardRoute, {
	layout: genericProjectLayout,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: classicBoardSidebars,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
	},

	component: LegacyPage,
	resources: getNavigationSidebarProjectResource(),
});

export const legacyBoard1RouteEntry = createEntry(legacyBoard1Route, {
	layout: genericProjectLayout,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: classicBoardSidebars,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
	},

	component: LegacyPage,
	resources: getNavigationSidebarProjectResource(),
});
