import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationSidebarProductsSettingResource } from '@atlassian/jira-router-resources-navigation';
import { globalSettingsProductsJsdStatusRoute } from '@atlassian/jira-router-routes-legacy-global-settings-products-jsd-status-routes';

export const globalSettingsProductsJsdStatusRouteEntry = createEntry(
	globalSettingsProductsJsdStatusRoute,
	{
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

		resources: getNavigationSidebarProductsSettingResource(),
	},
);
