// like !default / !edit / %21viewLogs
import { ROUTE_NAMES_LEGACY_REPORTS_PIE } from '@atlassian/jira-common-constants/src/spa-routes';

const defaultSuffix = '([!%]?[\\w]*)';

export const legacyReportsPieRoute = {
	name: ROUTE_NAMES_LEGACY_REPORTS_PIE,
	path: `/secure/ConfigureReport${defaultSuffix}.jspa`,
	query: ['reportKey=com.atlassian.jira.plugin.system.reports:pie-report'],
};
