import {
	ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_TRANSITION,
	ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_TRANSITION_META_ATTRIBUTES,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsIssuesWorkflowTransitionRoute = {
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_TRANSITION,
	path: '/secure/admin/ViewWorkflowTransition.jspa',
};

export const globalSettingsIssuesWorkflowTransitionMetaAttributesRoute = {
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_TRANSITION_META_ATTRIBUTES,
	path: '/secure/admin/ViewWorkflowTransitionMetaAttributes.jspa',
};
