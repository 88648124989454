import { useEffect } from 'react';
import {
	clearShowOldEditorSucccessFlag,
	getShouldShowOldEditorSucccessFlag,
} from '../../services/update-preference-session';
import { useChangePreferenceFlag } from '../change-preference-flag';

// Show flag when show old editor flag is true in session storage
export const useChangeToOldEditorSuccessFlag = ({
	openDefaultEditorDialog,
}: {
	openDefaultEditorDialog: () => void;
}) => {
	const { showOldEditorSuccessFlag } = useChangePreferenceFlag();

	useEffect(() => {
		if (getShouldShowOldEditorSucccessFlag()) {
			clearShowOldEditorSucccessFlag();
			showOldEditorSuccessFlag(openDefaultEditorDialog);
		}
	}, [openDefaultEditorDialog, showOldEditorSuccessFlag]);

	return { showOldEditorSuccessFlag };
};
