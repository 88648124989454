import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { globalSettingsCustomFieldsRoute } from '@atlassian/jira-router-routes-legacy-global-settings-custom-fields-routes';

export const globalSettingsCustomFieldsRouteEntry = createEntry(globalSettingsCustomFieldsRoute, {
	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsIssues,
	},

	resources: [navigationSidebarGlobalResource],
});
