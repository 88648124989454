// like !default / !edit / %21viewLogs
import { ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS } from '@atlassian/jira-common-constants/src/spa-routes';

const defaultSuffix = '([!%]?[\\w]*)';

export const legacyProjectSettingsCollectorsRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS,
	path: `/secure/ViewCollectors${defaultSuffix}.jspa`,
	query: ['projectKey'],
};
