import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationSidebarProjectResource } from '@atlassian/jira-router-resources-navigation';
import { legacyReportsTimeTrackingReportRoute } from '@atlassian/jira-router-routes-legacy-reports-time-tracking-report-routes';
import { getReportsCommonResource } from './common/ui/resources/reports';

export const legacyReportsTimeTrackingReportRouteEntry = createEntry(
	legacyReportsTimeTrackingReportRoute,
	{
		layout: genericProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: genericProjectSidebars,
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
		},

		resources: [
			...getBusinessProjectResources(),
			...getNavigationSidebarProjectResource(),
			...getReportsCommonResource(),
			getConsolidationStateResource(),
		],
	},
);
