// like !default / !edit / %21viewLogs
import { ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_EDIT } from '@atlassian/jira-common-constants/src/spa-routes';

const secure = '(/secure/|/)';
const defaultSuffix = '([!%]?[\\w]*)';

export const legacyProjectSettingsEditRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_EDIT,
	path: `${secure}project/EditProject${defaultSuffix}.jspa`,
	query: ['pid'],
};
